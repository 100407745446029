import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { Alert } from "antd";
import { Row } from "react-table";
import isEqual from "lodash/isEqual";
import "../../../scss/base.scss";
import "../../../scss/buttons.scss";
import "../../../scss/shoppingTables.scss";
import "../../../scss/loader.scss";
import { authService } from "../../../libs/Authentication";
import { WideContainer } from "../../../components/containers/WideContainer";
import { getPrescriptionGroups } from "../../../services/prescriptions";
import DispensedItem from "../../../components/DispensedItem";
import { SelectSupplierModal } from "../../../components/shopping/SelectSupplierModal/SelectSupplierModal";
import RadioButtonsGroup from "../../../components/RadioButtonsGroup";
import { DispensedQuantityHistory } from "./DispensedQuantityHistory";
import { Table } from "../../../components/Table";
import BuyingPharmacyContext from "../../../contexts/BuyingPharmacyContext";
import {
  useShoppingState,
  useShoppingUpdater,
} from "../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import {
  DrugType,
  ItemInCart,
  ItemPurchaseDetails,
  PrescriptionGroup,
  PrescriptionGroupAdjustment,
  PrescriptionQuantityItem,
  PurchaseQuantityMethodEnum,
  TableCellPropsType,
  PrescriptionGroupsRequestItem,
} from "../../../utilities/types";
import {
  useStorageState,
  useStorageUpdater,
} from "../../../contexts/shoppingContexts/StorageContext";
import {
  useShoppingCartServerState,
  useShoppingCartServerUpdater,
} from "../../../contexts/shoppingContexts/ShoppingCartServerContext/ShoppingCartServerContext";
import { formatPurchaseByToDayjs } from "../../../utilities/prescriptions/purchaseBy/formatPurchaseByToDayjs";
import { isInventoryId } from "../../../utilities/prescriptions/isInventoryId";
import { PmsEnum } from "../../../utilities/pms";
import { useManageColumnOptions } from "./useManageColumnOptions";
import { ShoppingBar } from "./ShoppingBar";
import { FutureRefills } from "./FutureRefills";
import PurchaseQuantity from "./PurchaseQuantity/PurchaseQuantity";
import { ShoppingListTwoManageColumnsDropdown } from "./ShoppingListTwoManageColumnsDropdown";
import { NumberOfUnitsCalendar } from "./NumberOfUnitsCalendar";
import { findAdjustmentMatch } from "./ShoppingListTwo.findAdjustmentMatch";
import { PrescriptionTable } from "./PrescriptionTable/PrescriptionTable";
import { ChosenSubstitutions } from "./ChosenSubstitutions";

const BLOB_PUSH_TIME = 2000;

const SEE_OPTIONS = [
  { value: DrugType.ALL, label: "Generic + Brand" },
  { value: DrugType.GENERIC, label: "Generic" },
  { value: DrugType.BRAND, label: "Brand" },
];

type TableCell = TableCellPropsType<PrescriptionQuantityItem, Row>;

function renderRowSubComponent({
  row,
}: Record<
  "row",
  Record<"original", Record<"dispensedQuantity", PrescriptionQuantityItem>>
>) {
  return <PrescriptionTable prescription={row.original.dispensedQuantity} />;
}

function createPrescriptionGroupsRequest(
  itemPurchaseDetails: ItemPurchaseDetails[]
): PrescriptionGroupsRequestItem[] {
  const prescriptionGroupsRequest = itemPurchaseDetails.map((item) => {
    const isInventory = isInventoryId(item.id);
    const obj: PrescriptionGroupsRequestItem = {
      rxNumber: item.id,
      allowPackSizeSubstitution: item.packSize || false,
      allowManufacturerSubstitution: item.manufactutrer || false,
      purchaseBy: item.purchaseBy,
      isCustom: isInventory
    };

    if (isInventoryId(item.id)) {
      obj.ndc = item.ndc;
      obj.numPackages = item.packQuantity || 1;
      obj.noManufacturerPreference = item.noManufacturerPreference;
    } else {
      obj.currentFillId = item.currentFillId;
    }

    return obj;
  });

  return prescriptionGroupsRequest;
}

export function ShoppingListTwo() {
  const {
    addInterval,
    setOfferedDrugs,
    setWaitButtonMode,
    setPrescriptionData,
  } = useStorageUpdater();
  const { prescriptionData, offeredDrugs } = useStorageState();
  const { itemPurchaseDetailsInCart, prescriptionItemsInCart } =
    useShoppingState();
  const { inventory, initialShoppingPath, prescriptionGroupAdjustments } =
    useShoppingCartServerState();

  const {
    deletePrescriptionGroupAdjustments,
    updatePrescriptionGroupPurchaseQuantity,
    updatePrescriptionGroupChosenSubstitution,
  } = useShoppingUpdater();
  const { pushBlob } = useShoppingCartServerUpdater();
  const {
    isShowInventory,
    isShowFutureRefills,
    manageColumnOptions,
    mainTableInitialState,
    futureFillOptionDataValue,
    updateManageColumnOptions,
  } = useManageColumnOptions();

  const [supplierModalActive, setSupplierModalActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [drugType, setDrugType] = useState(DrugType.ALL);
  const [isInvalidQty, setIsInvalidQty] = useState(false);
  const [
    defunctPrescriptionGroupAdjustments,
    setDefunctPrescriptionGroupAdjustments,
  ] = useState<PrescriptionGroupAdjustment[]>([]);
  const [optimizationErrorMessage, setOptimizationErrorMessage] = useState("");

  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const { id: pharmacyId, pms } = currentBuyingPharmacy ?? {};
  const isLiberty = pms === PmsEnum.Liberty;

  const hasPurchaseByItems = useMemo(
    () =>
      itemPurchaseDetailsInCart.some((item) => {
        return formatPurchaseByToDayjs(item.purchaseBy);
      }),
    [itemPurchaseDetailsInCart]
  );

  const updatePurchaseQuantity = useCallback(
    (
      rxNumbers: string[],
      purchaseQuantityMethod: PurchaseQuantityMethodEnum,
      num?: string
    ) => {
      const newPrescriptionData = prescriptionData.map((item) => {
        if (!isEqual(rxNumbers, item.rxNumbers)) return item;

        const useQuantityInput =
          purchaseQuantityMethod === PurchaseQuantityMethodEnum.Manual;

        const newItem = { ...item, useQuantityInput };
        if (num !== undefined) {
          // Update purchase quantity (input quantity manually or select numPackages based on the radio selection)
          if (useQuantityInput) {
            newItem.quantityToBuy = parseInt(num, 10);
          } else {
            newItem.numPackages = parseInt(num, 10);
          }
        }

        return newItem;
      });
      setPrescriptionData(newPrescriptionData);

      updatePrescriptionGroupPurchaseQuantity(
        rxNumbers,
        purchaseQuantityMethod,
        num
      );
      setTimeout(() => {
        setWaitButtonMode(false);
      }, 0);
    },
    [
      prescriptionData,
      setWaitButtonMode,
      setPrescriptionData,
      updatePrescriptionGroupPurchaseQuantity,
    ]
  );

  const updateChosenSubstitution = useCallback(
    (
      rxNumbers: string[],
      allowPackSizeSubstitution: boolean,
      allowManufacturerSubstitution: boolean
    ) => {
      setPrescriptionData((prevState: PrescriptionQuantityItem[]) => {
        return prevState.map((item: PrescriptionQuantityItem) => {
          if (item.rxNumbers === rxNumbers) {
            return {
              ...item,
              allowPackSizeSubstitution,
              allowManufacturerSubstitution,
            };
          }
          return item;
        });
      });
      updatePrescriptionGroupChosenSubstitution(
        rxNumbers,
        allowPackSizeSubstitution,
        allowManufacturerSubstitution
      );
    },
    [setPrescriptionData, updatePrescriptionGroupChosenSubstitution]
  );

  useEffect(() => {
    if (!loaded) return;

    const blobInterval = setInterval(() => {
      pushBlob({ second: false, force: false });
    }, BLOB_PUSH_TIME);

    addInterval({
      id: pharmacyId ?? null,
      intervalId: blobInterval,
    });

    return () => {
      clearInterval(blobInterval);
    };
  }, [loaded]);

  const sortShoppingTbl = () => {
    setPrescriptionData((prevState) => {
      const sorted = [...prevState];

      return sorted.sort((a, b) => {
        // dispensed item, same as SelectDrug
        const aDrug = a.drug;
        const bDrug = b.drug;

        // ------ Name
        const firstName = aDrug.name.toLocaleLowerCase();
        const secondName = bDrug.name.toLocaleLowerCase();
        if (firstName < secondName) {
          return -1;
        }
        if (firstName > secondName) {
          return 1;
        }

        // ------ Form
        // tablet
        // capsule
        // other
        const firstForm = aDrug.form
          ? aDrug.form.toLocaleLowerCase() === "tablet"
            ? "a"
            : aDrug.form.toLocaleLowerCase() === "capsule"
            ? "b"
            : aDrug.form.toLocaleLowerCase()
          : "x";
        const secondForm = bDrug.form
          ? bDrug.form.toLocaleLowerCase() === "tablet"
            ? "a"
            : bDrug.form.toLocaleLowerCase() === "capsule"
            ? "b"
            : bDrug.form.toLocaleLowerCase()
          : "x";
        if (firstForm < secondForm) {
          return -1;
        }
        if (firstForm > secondForm) {
          return 1;
        }

        // ------ Strength Unit
        const firstStrengthUni = aDrug.strength
          ? aDrug.strengthUnit
            ? aDrug.strengthUnit
            : "x"
          : "y";
        const secondStrengthUni = bDrug.strength
          ? bDrug.strengthUnit
            ? bDrug.strengthUnit
            : "x"
          : "y";
        if (firstStrengthUni < secondStrengthUni) {
          return -1;
        }
        if (firstStrengthUni > secondStrengthUni) {
          return 1;
        }

        // ------ Strength Unit
        const firstStrength = aDrug.strength ? aDrug.strength : "x";
        const secondStrength = bDrug.strength ? bDrug.strength : "x";

        if (isNaN(Number(firstStrength)) || isNaN(Number(secondStrength))) {
          const aa = firstStrength.split(/(\d+)/);
          const bb = secondStrength.split(/(\d+)/);

          for (let i = 0; i < Math.max(aa.length, bb.length); i++) {
            if (aa[i] != bb[i]) {
              const cmp1 = isNaN(parseInt(aa[i], 10))
                ? aa[i]
                : parseInt(aa[i], 10);
              const cmp2 = isNaN(parseInt(bb[i], 10))
                ? bb[i]
                : parseInt(bb[i], 10);
              if (cmp1 == undefined || cmp2 == undefined) {
                return aa.length - bb.length;
              } else {
                return cmp1 < cmp2 ? -1 : 1;
              }
            }
          }
        } else {
          if (Number(firstStrength) < Number(secondStrength)) {
            return -1;
          }
          if (Number(firstStrength) > Number(secondStrength)) {
            return 1;
          }
        }

        return 0;
      });
    });
  };

  const getRxObj = (rxNumber: string, newInv: boolean) => {
    if (newInv) {
      return inventory.find((item) => item.key === rxNumber);
    } else {
      return prescriptionItemsInCart.find((item) => item.rxNumber === rxNumber);
    }
  };

  const adjustDataItems = (
    items: PrescriptionGroup[],
    newPrescriptionGroupAdjustments: PrescriptionGroupAdjustment[]
  ) => {
    console.log("adjustDataItems", items, newPrescriptionGroupAdjustments);

    const adjustmentsToDelete: PrescriptionGroupAdjustment[] = [];
    const mergedItems = items.map((item: PrescriptionGroup) => {
      let isInv = false;
      const rxList: ItemInCart[] = [];
      const sortedRxNumbers = [...item.rxNumbers].sort(
        (a: string, b: string) => {
          const aValue = a.replace("c", "");
          const bValue = b.replace("c", "");

          if (Number(aValue) < Number(bValue)) {
            return -1;
          }
          if (Number(aValue) > Number(bValue)) {
            return 1;
          }
          return 0;
        }
      );
      sortedRxNumbers.forEach((id: string) => {
        isInv = isInventoryId(id);
        const item = getRxObj(id, isInv) as ItemInCart;
        rxList.push(item);
      });
      const adjustmentMatch = findAdjustmentMatch(
        sortedRxNumbers,
        newPrescriptionGroupAdjustments
      );
      console.log("adjustmentMatch", adjustmentMatch);
      let adjustmentRemoved = false;
      if (adjustmentMatch && adjustmentMatch.matchType === "partial") {
        adjustmentRemoved = true;
      }
      if (
        adjustmentMatch &&
        (adjustmentMatch.matchType === "partial" ||
          adjustmentMatch.matchType === "full-and-partial")
      ) {
        adjustmentsToDelete.push(...adjustmentMatch.defunctAdjustments);
      }
      if (
        adjustmentMatch &&
        (adjustmentMatch.matchType === "full" ||
          adjustmentMatch.matchType === "full-and-partial")
      ) {
        const adjustment = adjustmentMatch.matchingAdjustment;
        return {
          ...item,
          ...adjustment,
          adjustmentRemoved,
          rxNumbers: sortedRxNumbers,
          inv: isInv,
          rxList: rxList,
        };
      } else {
        return {
          ...item,
          adjustmentRemoved,
          rxNumbers: sortedRxNumbers,
          inv: isInv,
          rxList: rxList,
        };
      }
    });

    if (adjustmentsToDelete.length > 0) {
      setDefunctPrescriptionGroupAdjustments(adjustmentsToDelete);
    }

    setPrescriptionData(mergedItems);
    sortShoppingTbl();
  };

  useEffect(() => {
    if (defunctPrescriptionGroupAdjustments.length > 0) {
      deletePrescriptionGroupAdjustments(defunctPrescriptionGroupAdjustments);
      setDefunctPrescriptionGroupAdjustments([]);
    }
  }, [defunctPrescriptionGroupAdjustments]);

  useEffect(() => {
    (async () => {
      if (!loaded && pharmacyId && itemPurchaseDetailsInCart.length) {
        setLoading(true);

        // Time latency promise to show loading spinner for about 2s at least
        const timeLatencyPromise = new Promise((res) =>
          setTimeout(() => res(true), 2000)
        );

        // Data fetch promise to get data from API
        const dataFetchPromise = new Promise((res) => {
          const fetchResult = async () => {
            const token = await authService.getAccessTokenSilently();
            const prescriptionGroupsRequest = createPrescriptionGroupsRequest(
              itemPurchaseDetailsInCart
            );

            const source = getShoppingPageSource(initialShoppingPath);
            const { data } = await getPrescriptionGroups(
              pharmacyId ?? null,
              token,
              prescriptionGroupsRequest,
              source
            );

            if (data?.error || !data) {
              setHasError(true);
            } else {
              if (
                data?.prescriptionGroups?.length === 0 &&
                data?.offeredDrugs?.length === 0
              ) {
                setHasError(true);
              } else {
                if (data?.prescriptionGroups) {
                  adjustDataItems(
                    data?.prescriptionGroups,
                    prescriptionGroupAdjustments
                  );
                }
                if (data?.offeredDrugs) {
                  setOfferedDrugs(data?.offeredDrugs);
                }
              }
            }

            return res(true);
          };

          const resultPromise = fetchResult();

          return resultPromise;
        });

        // Wait for both
        await Promise.all([timeLatencyPromise, dataFetchPromise]);
        setLoading(false);
        setLoaded(true);
      }
    })();
  }, [loaded, pharmacyId, initialShoppingPath, itemPurchaseDetailsInCart]);

  useEffect(() => {
    setIsInvalidQty(false);

    const validQuantityIndex = prescriptionData.findIndex(
      (item: PrescriptionQuantityItem) =>
        (!item.useQuantityInput && item.numPackages > 0) ||
        (item.useQuantityInput && item.quantityToBuy > 0)
    );
    // All prescription data items have 0 Qty
    if (validQuantityIndex === -1) {
      setIsInvalidQty(true);
    }
  }, [prescriptionData]);

  const tableData = useMemo(() => {
    // Update PrescriptionData depending on item type radio button selection (generic / brand / all)
    return prescriptionData
      .filter((item: PrescriptionQuantityItem) => {
        switch (drugType) {
          case DrugType.BRAND:
            return item.drug.isBrand;
          case DrugType.GENERIC:
            return !item.drug.isBrand;
          case DrugType.ALL:
            return true;
        }
      })
      .map((prescription) => ({
        dispensedItem: prescription,
        dispensedQuantity: prescription,
        futureRefills: prescription,
        neededToday: prescription,
        purchaseQuantity: prescription,
        chosenSubstitutions: prescription,
      }));
  }, [drugType, prescriptionData]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Item To Buy",
        accessor: "dispensedItem",
        maxWidth: !isShowFutureRefills && !isShowInventory ? 260 : 90,
        Cell: function renderDispensedItemCell({ cell }: TableCell) {
          const prescription = cell.value;
          return (
            <DispensedItem
              drug={prescription.drug}
              showManufacturer={true}
              ndc={!prescription.noManufacturerPreference}
              noManufacturerPreference={prescription.noManufacturerPreference}
              alert={
                prescription.adjustmentRemoved
                  ? "Please verify proposed quantity"
                  : undefined
              }
            />
          );
        },
      },
      {
        Header: isLiberty ? "BOH" : "BOH / EOH",
        accessor: "dispensedQuantity",
        maxWidth: 40,
        classNames: "dispensed-quantity__cell",
        headerClassNames: "dispensed-quantity__th",
        Cell: function renderDispensedQuantityCell({ cell }: TableCell) {
          const prescription = cell.value;
          const eoh = isLiberty ? undefined : prescription.eoh;
          return <DispensedQuantityHistory boh={prescription.boh} eoh={eoh} />;
        },
      },
      {
        Header: `Upcoming Refills${pms ? ` (From ${pms})` : ""}`,
        accessor: "futureRefills",
        maxWidth: 90,
        classNames: "future-refills__cell",
        headerClassNames: "future-refills__th",
        Cell: function renderFutureRefillsCell({ cell }: TableCell) {
          const prescription = cell.value;
          if (!futureFillOptionDataValue) return null;
          return (
            <FutureRefills
              prescription={prescription}
              futureFillOption={futureFillOptionDataValue}
            />
          );
        },
      },
      {
        Header: "Number of Units Added to List",
        accessor: "neededToday",
        maxWidth: !isShowFutureRefills && !isShowInventory ? 65 : 55,
        classNames: "needed-today__cell",
        Cell: function renderNumberOfUnitsCalendarCell({ cell }: TableCell) {
          const { value: prescription, row } = cell;
          return (
            <NumberOfUnitsCalendar prescription={prescription} row={row} />
          );
        },
      },
      {
        Header: hasPurchaseByItems ? "Units to Buy" : "Purchase Quantity",
        accessor: "purchaseQuantity",
        maxWidth: !isShowFutureRefills && !isShowInventory ? 190 : 180,
        Cell: function renderPurchaseQuantity({ cell }: TableCell) {
          const prescription = cell.value;
          return (
            <PurchaseQuantity
              prescription={prescription}
              updatePurchaseQuantity={updatePurchaseQuantity}
            />
          );
        },
      },
      {
        Header: "Chosen Substitutions",
        accessor: "chosenSubstitutions",
        maxWidth: !isShowFutureRefills && !isShowInventory ? 120 : 58,
        Cell: function renderChosenSubstitutionsCell({ cell }: TableCell) {
          const prescription = cell.value;
          if (!prescription) return null;
          return (
            <ChosenSubstitutions
              prescription={prescription}
              updateChosenSubstitution={updateChosenSubstitution}
            />
          );
        },
      },
    ];
  }, [
    isLiberty,
    isShowInventory,
    isShowFutureRefills,
    futureFillOptionDataValue,
    updatePurchaseQuantity,
    updateChosenSubstitution,
  ]);

  return (
    <div className="shopping-list-two bg-gray4" tw="pb-[200px]">
      <div className="sticky z-999 top-0 bg-gray4">
        <ShoppingBar
          loading={loading}
          isInvalidQty={isInvalidQty}
          setSupplierModalActive={setSupplierModalActive}
        />

        {!loading && !!manageColumnOptions && (
          <WideContainer className="bg-transparent mt-12">
            {optimizationErrorMessage && (
              <Alert
                message="Error"
                description={optimizationErrorMessage}
                type="error"
                showIcon
                closable
                onClose={() => setOptimizationErrorMessage("")}
              />
            )}

            <Box
              className="shopping-list-two__filter-wrapper"
              sx={{
                display: "flex",
                justityContent: "space-between",
                alignItems: "center",
                pt: 4,
                pb: 1,
              }}
            >
              <RadioButtonsGroup
                groupLabel="See:"
                selectedValue={drugType}
                options={SEE_OPTIONS}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setDrugType(e.target.value as DrugType)
                }
              />
              <ShoppingListTwoManageColumnsDropdown
                manageColumnOptions={manageColumnOptions}
                updateManageColumnOptions={updateManageColumnOptions}
              />
            </Box>
          </WideContainer>
        )}
      </div>

      {loading ? (
        <WideContainer className="p2-loading-screen flex items-center justify-center flex-col">
          <div className="p2-loading-wrapper">
            <img
              src="/../assets/pages/shopping-list-two/pharmacy-outline.gif"
              alt="loading"
            />
          </div>
          <p className="color-orange text-xl mt-27">
            Calculating Proposed Quantities
          </p>
        </WideContainer>
      ) : (
        <WideContainer tw="h-full">
          <Table
            columns={columns}
            data={tableData}
            alert={
              prescriptionData.some((p) => p.adjustmentRemoved)
                ? "Since your last visit to this page, the requested details were modified - please review the indicated rows below."
                : undefined
            }
            renderRowSubComponent={renderRowSubComponent}
            variant="custom"
            classNames="shopping-list-table"
            initialState={mainTableInitialState}
            loading={loading}
            hasError={hasError}
            noDataComponent={
              <div className="text-center pt-12 text-lg">
                <p className="text-center text-lg mt-48">
                  No{" "}
                  {drugType === DrugType.BRAND
                    ? "brand"
                    : drugType === DrugType.GENERIC
                    ? "generic"
                    : "brand and generic"}{" "}
                  drugs in shopping list.{" "}
                  <Link
                    to="/shoppingListTwo"
                    style={{ textDecoration: "underline" }}
                    onClick={() => setDrugType(DrugType.ALL)}
                  >
                    Show all Items
                  </Link>
                  .
                </p>
              </div>
            }
            errorComponent={
              <div className="text-center pt-12 text-lg">
                <p className="text-center text-lg mt-48">
                  There was a temporary error. Please{" "}
                  <Link
                    to="/shoppingList"
                    style={{ textDecoration: "underline" }}
                  >
                    return to the previous screen
                  </Link>{" "}
                  and try again.
                </p>
              </div>
            }
          />
        </WideContainer>
      )}

      <SelectSupplierModal
        prescriptionData={prescriptionData}
        offeredDrugs={offeredDrugs}
        modalActive={supplierModalActive}
        onClose={(mode: boolean) => {
          setSupplierModalActive(mode);
        }}
        onOptimizationError={(error) => {
          setOptimizationErrorMessage(
            error ||
              "An error occurred while optimizing your shopping list. Please try again or contact us for help."
          );
        }}
      />
    </div>
  );
}


function getShoppingPageSource(pathname?: string) {
  if (!pathname) return
  if (pathname.includes("/shopping-list-add-rx")) return "addRx";
  if (pathname.includes("/shoppingList")) return "p1";
}