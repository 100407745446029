import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import dayjs from "dayjs";
import keyBy from "lodash/keyBy";
import type { Manufacturer } from "../../../utilities/types";
import { LAST_MANUFACTURER_REPORT_ID_KEY } from "../../../utilities/localStorage/constants";
import { setLocalStorageValue } from "../../../utilities/localStorage/setLocalStorageValue";
import { useReplaceQueryParams } from "../../../utilities/queryParams/useReplaceQueryParams";
import { FullPageLoader } from "../../../components/loaders/FullPageLoader";
import { ReportError } from "../../../components/errors/ReportError";
import ManufacturersContext from "../../../contexts/ManufacturersContext";
import {
  ManufacturerReport,
  ManufacturerReportItem,
  BaseManufacturerReport,
  MANUFACTURER_REPORTS,
} from "../Manufacturer.constants";
import { getInitialManufacturerReport } from "./useManufacturerReport.getInitialManufacturerReport";

const ManufacturerReportContext = createContext<
  | {
      report: ManufacturerReportItem;
      reports: ManufacturerReport[];
      reportDates: [dayjs.Dayjs, dayjs.Dayjs];
      setReport: (report: ManufacturerReportItem) => void;
      setReportDates: (dates: [dayjs.Dayjs, dayjs.Dayjs]) => void;
    }
  | undefined
>(undefined);

function getInitialReportDates(): [dayjs.Dayjs, dayjs.Dayjs] {
  return [dayjs().subtract(7, "day"), dayjs().subtract(1, "day")];
}

function formatManufacturerReport(
  reportsById: Record<number, Manufacturer["reports"][number]>,
  item: BaseManufacturerReport,
  key: string
): ManufacturerReport {
  if ("children" in item) {
    const children = item.children.map((child, index) => {
      const childKey = `${key}.children[${index}]`;
      return formatManufacturerReport(reportsById, child, childKey);
    });
    return { ...item, key, children };
  }

  const report = reportsById[item.reportId];
  return { ...item, label: report.title, key };
}

function filterReportsByPermissions(
  reports: BaseManufacturerReport[],
  reportsById: Record<number, Manufacturer["reports"][number]>
): BaseManufacturerReport[] {
  const filteredReports = reports.reduce<typeof reports>((items, item) => {
    if ("children" in item) {
      const children = filterReportsByPermissions(item.children, reportsById);

      if (!children.length) return items;

      const newItem = { ...item, children: children };
      items.push(newItem);
      return items;
    }

    const report = reportsById[item.reportId];
    if (report) items.push(item);
    return items;
  }, []);

  return filteredReports;
}

export function ManufacturerReportContextProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const replaceQueryParams = useReplaceQueryParams();

  const [report, setReport] = useState<ManufacturerReportItem>();
  const [reports, setReports] = useState<ManufacturerReport[]>();
  const [reportDates, setReportDates] = useState(getInitialReportDates);
  const { manufacturer } = useContext(ManufacturersContext);
  const { id: manufacturerId, reports: manufacturerReports } =
    manufacturer ?? {};

  useEffect(() => {
    if (!manufacturerReports) {
      setReport(undefined);
      setReports(undefined);
      return;
    }

    const reportsById = keyBy(manufacturerReports, "id");
    const filteredReports = filterReportsByPermissions(
      MANUFACTURER_REPORTS,
      reportsById
    );
    const formattedReports = filteredReports.map((item, index) => {
      return formatManufacturerReport(reportsById, item, `[${index}]`);
    });

    const initialReport = getInitialManufacturerReport(formattedReports);
    setReport(initialReport);
    setReports(formattedReports);
  }, [manufacturerReports]);

  useEffect(() => {
    if (!report) return;

    const { reportId } = report;
    const [startDate, endDate] = reportDates;

    setLocalStorageValue(LAST_MANUFACTURER_REPORT_ID_KEY, reportId);
    const newQueryParams: Record<string, string> = {
      reportId: reportId.toString(),
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    };
    if (manufacturerId) {
      newQueryParams.manufacturerId = manufacturerId.toString();
    }

    replaceQueryParams(newQueryParams);
  }, [report, reportDates, manufacturerId, replaceQueryParams]);

  if (!reports) return <FullPageLoader text="Loading Reports" />;
  if (!report) return <ReportError />;

  return (
    <ManufacturerReportContext.Provider
      value={{
        report,
        reports,
        reportDates,
        setReport,
        setReportDates,
      }}
    >
      {children}
    </ManufacturerReportContext.Provider>
  );
}

export function useManufacturerReport() {
  const context = useContext(ManufacturerReportContext);
  if (!context) {
    throw new Error(
      "useManufacturerReport must be used within a ManufacturerReportContextProvider"
    );
  }
  return context;
}
