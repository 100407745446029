import React, { useContext, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import type { Supplier } from "../../utilities/types";
import { Box } from "../../components/rxLibrary/box";
import { FullPageLoader } from "../../components/loaders/FullPageLoader";
import { RefillOpportunities } from "../../components/RefillOpportunities/RefillOpportunities";
import { RedirectHomePage } from "../../components/RedirectHomePage";
import { useBuyingPharmacy } from "../../contexts/BuyingPharmacyContext";
import { IS_ELECTRON } from "../../utilities/config";
import EnhancedClientEcommerceContext from "../../enhanced/EnhancedClientEcommerceContext";
import { OrderHistory } from "./pages/OrderHistory/OrderHistory";
import { Completed } from "./pages/Completed";
import { Catalog } from "./pages/Catalog/Catalog";
import { AccountSettings } from "./pages/AccountSettings";
import { Invoices } from "./pages/Invoices";
import { useManufacturerStoreSupplier } from "./useManufacturerStoreSupplier";
import { ManufacturerStoreHeader } from "./ManufacturerStoreHeader/ManufacturerStoreHeader";
import { ManufacturerStoreNavBar } from "./ManufacturerStoreNavBar";
import { ManufacturerStoreWrapper } from "./ManufacturerStoreWrapper";

export function ManufacturerStore() {
  const { willNavigateToNewEcommercePage } = useContext(
    EnhancedClientEcommerceContext
  );
  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const { supplierId, supplier } = useManufacturerStoreSupplier();
  useManufacturerPageTitle(supplier);

  if (
    !IS_ELECTRON &&
    (!supplierId || (supplier && !supplier.isEcommerceEnabled))
  ) {
    return <RedirectHomePage />;
  }

  if (!supplier || !currentBuyingPharmacy || willNavigateToNewEcommercePage) {
    return (
      <>
        <ManufacturerStoreNavBar supplier={supplier} />
        <FullPageLoader />
      </>
    );
  }

  return (
    <>
      <ManufacturerStoreNavBar supplier={supplier} />
      <ManufacturerStoreHeader supplier={supplier} />

      <Switch>
        <Route path="/manufacturer-store/:supplierId" exact>
          <Catalog supplier={supplier} pharmacy={currentBuyingPharmacy} />
        </Route>

        <Route path="/manufacturer-store/:supplierId/invoices" exact>
          <ManufacturerStoreWrapper>
            <Invoices supplier={supplier} pharmacy={currentBuyingPharmacy} />
          </ManufacturerStoreWrapper>
        </Route>

        <Route path="/manufacturer-store/:supplierId/account-settings" exact>
          <ManufacturerStoreWrapper>
            <AccountSettings
              supplier={supplier}
              pharmacy={currentBuyingPharmacy}
            />
          </ManufacturerStoreWrapper>
        </Route>

        <Route
          path="/manufacturer-store/:supplierId/completed/:purchaseOrderNumbers"
          exact
        >
          <ManufacturerStoreWrapper>
            <Completed supplier={supplier} />
          </ManufacturerStoreWrapper>
        </Route>

        <Route path="/manufacturer-store/:supplierId/order-history" exact>
          <ManufacturerStoreWrapper>
            <OrderHistory
              pharmacy={currentBuyingPharmacy}
              supplierId={supplier.id}
            />
          </ManufacturerStoreWrapper>
        </Route>

        <Route
          path="/manufacturer-store/:supplierId/refill-opportunities"
          component={RefillOpportunities}
          exact
        >
          <Box tw="border-t-2" borderColor="grey-4">
            <RefillOpportunities />
          </Box>
        </Route>
      </Switch>
    </>
  );
}

function useManufacturerPageTitle(supplier?: Supplier) {
  const location = useLocation();

  useEffect(() => {
    if (!supplier) return;

    let pageTitle = supplier.displayName;
    if (location.pathname === `/manufacturer-store/${supplier.id}`) {
      pageTitle += " Catalog";
    } else if (location.pathname.includes("/order-history")) {
      pageTitle += " Order History";
    } else if (location.pathname.includes("/account-settings")) {
      pageTitle += " Account Settings";
    } else if (location.pathname.includes("/refill-opportunities")) {
      pageTitle += " Refill Opportunities";
    }
    document.title = pageTitle;

    return () => {
      document.title = "DaylightRx";
    };
  }, [supplier, location.pathname]);
}
