import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import keyBy from "lodash/keyBy";
import sortBy from "lodash/sortBy";
import isEqual from "lodash/isEqual";
import type { Prescription } from "../../../utilities/types";
import { ensureArray } from "../../../utilities/arrays/ensureArray";
import {
  useShoppingCartServerState,
  useShoppingCartServerUpdater,
} from "../ShoppingCartServerContext/ShoppingCartServerContext";
import { useShoppingUpdater } from "../ShoppingContext/ShoppingContext";
import { useBuyingPharmacy } from "../../BuyingPharmacyContext";
import { useStorageUpdater } from "../StorageContext";
import { useServerUpdateNotifications } from "../ServerUpdateNotificationsContext";
import { mergePrescriptionsAndCartData } from "../mergePrescriptionsAndCartData";
import { getRxNumbersInCartToRemove } from "./getRxNumbersInCartToRemove";
import { fetchPrescriptionsFromCart } from "./fetchPrescriptionsFromCart";

export function useSyncedShoppingCart() {
  const location = useLocation();
  const { currentBuyingPharmacyId: pharmacyId } = useBuyingPharmacy();
  const { cart, isCartStateLoading } = useShoppingCartServerState();
  const { pushBlob, setUseBlob, loadCartState, setInitialShoppingPath } =
    useShoppingCartServerUpdater();
  const { activePrescriptionsPulledEvent } = useServerUpdateNotifications();
  const { removePrescription, setPrescriptionItemsInCart } =
    useShoppingUpdater();
  const { setWaitButtonMode, addInterval, resetInterval } = useStorageUpdater();

  const [inventorySyncDate, setInventorySyncDate] = useState<Dayjs>();
  const [isFetchingPrescriptions, setIsFetchingPrescriptions] = useState(true);
  const [prescriptionsSyncDate, setPrescriptionsSyncDate] = useState<Dayjs>();
  const [prescriptions, setPrescriptions] = useState<Prescription[]>();

  const isShoppingStateInitiated = !!prescriptionsSyncDate;
  const isPrescriptionsLoading = isFetchingPrescriptions || isCartStateLoading;

  const refreshCartState = useCallback(async () => {
    if (!pharmacyId) return;
    await loadCartState();
    setInventorySyncDate(dayjs());
  }, [pharmacyId, loadCartState]);

  const updatePrescriptions = useCallback((prescriptions: Prescription[]) => {
    const uniqPrescriptions = Object.values(keyBy(prescriptions, "rxNumber"));
    const newPrescriptions = sortBy(uniqPrescriptions, "rxNumber");
    setPrescriptions((prevState) => {
      if (isEqual(prevState, newPrescriptions)) return prevState;
      return newPrescriptions;
    });
  }, []);

  const addPrescriptions = useCallback(
    (value: Prescription | Prescription[]) => {
      if (!prescriptions) return;
      const newPrescriptions = ensureArray(value);
      updatePrescriptions([...newPrescriptions, ...prescriptions]);
    },
    [prescriptions, updatePrescriptions]
  );

  const syncPrescriptions = useCallback(
    async ({
      useCache,
    }: {
      useCache?: boolean;
    } = {}) => {
      if (!pharmacyId) return;

      setIsFetchingPrescriptions(true);
      setWaitButtonMode(true);

      const newPrescriptions = await fetchPrescriptionsFromCart({
        cart,
        useCache,
        pharmacyId,
        prescriptions,
      });
      updatePrescriptions(newPrescriptions);

      setPrescriptionsSyncDate(dayjs());
      setWaitButtonMode(false);
      setIsFetchingPrescriptions(false);
    },
    [cart, pharmacyId, prescriptions, setWaitButtonMode, updatePrescriptions]
  );

  const forcedSyncPrescriptions = useCallback(async () => {
    await syncPrescriptions({ useCache: false });
  }, [syncPrescriptions]);

  useEffect(() => {
    if (
      !prescriptions ||
      isCartStateLoading ||
      isPrescriptionsLoading ||
      !isShoppingStateInitiated
    ) {
      return;
    }

    const rxNumbersInCartToRemove = getRxNumbersInCartToRemove({
      cart,
      prescriptions,
    });
    if (rxNumbersInCartToRemove?.length) {
      removePrescription(rxNumbersInCartToRemove);
      return;
    }

    const newItemsInCart = mergePrescriptionsAndCartData(prescriptions, cart);
    setPrescriptionItemsInCart((prevState) => {
      if (isEqual(prevState, newItemsInCart)) return prevState;
      return newItemsInCart;
    });
  }, [
    cart,
    prescriptions,
    isCartStateLoading,
    isPrescriptionsLoading,
    isShoppingStateInitiated,
    removePrescription,
    setPrescriptionItemsInCart,
  ]);

  useEffect(() => {
    if (!isShoppingStateInitiated) return;
    setInitialShoppingPath(location.pathname);

    // start pushing
    const blobInterval = setInterval(() => {
      pushBlob({ second: false, force: false });
    }, 2000);
    addInterval({ id: pharmacyId, intervalId: blobInterval });

    return () => clearInterval(blobInterval);
  }, [isShoppingStateInitiated]);

  useEffect(() => {
    setPrescriptions(undefined);
    setInventorySyncDate(undefined);
    setPrescriptionsSyncDate(undefined);
    setPrescriptionItemsInCart([]);
    resetInterval();
    if (pharmacyId) setUseBlob(true);
  }, [pharmacyId]);

  useEffect(() => {
    if (activePrescriptionsPulledEvent > 0) {
      syncPrescriptions({ useCache: true });
      refreshCartState();
    }
  }, [pharmacyId, activePrescriptionsPulledEvent]);

  useEffect(() => {
    if (!prescriptions) return;
    syncPrescriptions();
  }, [cart, prescriptions]);

  return {
    inventorySyncDate,
    isCartStateLoading,
    prescriptionsSyncDate,
    isPrescriptionsLoading,
    addPrescriptions,
    refreshCartState,
    forcedSyncPrescriptions,
  };
}
