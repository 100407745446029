import React, {useContext, useEffect, useMemo, useState} from "react";
import semver from "semver";
import * as Sentry from "@sentry/react";
import "twin.macro";
import EnhancedClientContext from "../enhanced/EnhancedClientContext";
import { RequestState} from "../utilities/types";
import BuyingPharmacyContext from "../contexts/BuyingPharmacyContext";
import EnhancedClientConfigContext from "./EnhancedClientConfigContext";
import {EnhancedSupplierConnectionStatus} from "./types";

const SupplierLoginsDeferrableAsOfVersions: Record<string, string> = {
  "QK": "0.3.49",
  "MASTERS": "0.3.49",
  "GALDERMA_FFF": "0.3.51",
  "API": "0.3.56",
  "BONITA": "0.3.56",
  "OAK_DRUGS": "0.3.56",
  "PAR_MED": "0.3.56",
  "REALVALUE_RX": "0.3.56",
  "TOP_RX": "0.3.56",
  "ASTOR": "0.3.57",
  "BLUPAX": "0.3.58",
  "IPD": "0.3.60",
  "SOUTH_POINTE": "0.3.64",
  "PROTEGA": "0.3.67",
  "KEY_SOURCE": "0.3.73",
  "NDC_DISTRIBUTORS": "0.3.75",
  "INTEGRALRX": "0.3.75",
  "IPC": "0.3.75",
  "WELLGISTICS": "0.3.76",
  "PSI": "0.3.76",
  "HEALTHSOURCE": "0.3.76",
  "REPUBLIC": "0.3.76",
}

const AllUnenhancedLoginsDeferrableAsOfVersion = "0.3.79";

const isSupplierLoginDeferrable = (supplier: string, status: EnhancedSupplierConnectionStatus, appVersion: string) => {
  if (!status.enhancementsDisabled) {
    return false;
  }

  if (semver.gte(appVersion, AllUnenhancedLoginsDeferrableAsOfVersion)) {
    return true;
  }

  const deferrableVersion = SupplierLoginsDeferrableAsOfVersions[supplier];
  if (deferrableVersion) {
    return semver.gte(appVersion, deferrableVersion);
  } else {
    return false;
  }
}

function isUnexpiredPlaceholder(status: EnhancedSupplierConnectionStatus) {
  return status.placeholder && status._updatedAt > Date.now() - 30000;
}

export default function EnhancedSupplierLoginInterstitial({
    quoteRequestState,
    onLoginsFinished,
    onComplete,
    onTimeout,
    onCancelSupplierLogin,
    limitedTo,
    disableSearchingDisplay,
    searchId,
    expectedMaxEnhancementTimeSeconds,
} : {
    quoteRequestState?: RequestState,
    onLoginsFinished: VoidFunction,
    onComplete: VoidFunction,
    onTimeout: VoidFunction,
    onCancelSupplierLogin: (supplier: string) => void,
    limitedTo?: string[],
    disableSearchingDisplay?: boolean,
    searchId: string,
    expectedMaxEnhancementTimeSeconds?: number,
}): JSX.Element {
    const {  enhancedClientVersion } = useContext(EnhancedClientConfigContext);
    const { enhancedSupplierConnectionStatuses, showLogin, showMain, enhancementTimeout} = useContext(EnhancedClientContext);
    const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);

    const [currentLogin, setCurrentLogin] = useState<string | undefined>(undefined);
    const [loginsFinished, setLoginsFinished] = useState<boolean>(false);
    const [loginBoxCenterX, setLoginBoxCenterX] = useState<number>(0);
    const [loginBoxY, setLoginBoxY] = useState<number>(0);
    const [currentSearchingName, setCurrentSearchingName] = useState<string | undefined>(undefined);
    const [enhancementBeginTime, setEnhancementBeginTime] = useState<number>(Number.MAX_VALUE);

    const limitSet = useMemo(() => {
      if (limitedTo) {
        return new Set(limitedTo);
      } else {
        return new Set(currentBuyingPharmacy?.suppliers?.map(s => s.name) || []);
      }
    }, [limitedTo, currentBuyingPharmacy]);

    const loginBoxRefCallback = (element: HTMLDivElement) => {
        if (element) {
            const rect = element.getBoundingClientRect();
            if (rect.x === 0 || rect.y === 0) {
                return;
            }
            setLoginBoxCenterX(rect.x + Math.floor(rect.width / 2));
            setLoginBoxY(rect.y);
        }
    };

    useEffect(() => {
        console.log("Evaluating Search Interstitial State");
        if (!loginBoxY || !loginBoxCenterX) return () => {};
        if (!currentBuyingPharmacy) return () => {};
        if (Object.keys(enhancedSupplierConnectionStatuses).length === 0) {
          console.log("No suppliers connected; exiting search interstitial");
          onLoginsFinished();
          setLoginsFinished(true);
          console.log("Search Interstitial onComplete (logins)");
          onComplete();
          const time = new Date().getTime();
          console.log("Setting enhancement begin time", time);
          setEnhancementBeginTime(time)
          return () => {};
        }
        if (loginsFinished && Object.keys(enhancedSupplierConnectionStatuses).length > 0 && (!quoteRequestState || quoteRequestState === RequestState.COMPLETED)) {
          setCurrentLogin(undefined);
          const time = new Date().getTime();
          console.log("Setting enhancement begin time", time);
          setEnhancementBeginTime(time);
          console.log("Search Interstitial onComplete (logins)");
          onComplete();
          return () => {};
        }

        if (!loginsFinished) {
          const interval = setInterval(() => {
            let showingLogin = false;
            Object.keys(enhancedSupplierConnectionStatuses)
              .sort((a, b) => { return enhancedSupplierConnectionStatuses[b]._updatedAt - enhancedSupplierConnectionStatuses[a]._updatedAt})
              .forEach(k => {
                if (showingLogin) return;

                const supplier = currentBuyingPharmacy.suppliers.find(supplier => supplier.name === k);
                const status = enhancedSupplierConnectionStatuses[k];
                if (supplier
                  && !status.loggedIn
                  && !status.placeholder
                  && !isSupplierLoginDeferrable(k, status, enhancedClientVersion)
                  && limitSet.has(k)) {
                  showLogin(k, {center_x: loginBoxCenterX, y: loginBoxY});
                  setCurrentLogin(supplier.displayName)
                  showingLogin = true;
                }
              });

            const waitingForPlaceholderToResolve = !!Object.keys(enhancedSupplierConnectionStatuses).find(k => {
              const status = enhancedSupplierConnectionStatuses[k];
              return isUnexpiredPlaceholder(status)
                && !isSupplierLoginDeferrable(k, status, enhancedClientVersion)
                && limitSet.has(k);
            });

            if (!showingLogin && !waitingForPlaceholderToResolve) {
              console.log(enhancedSupplierConnectionStatuses);
              console.log("No logins to show; exiting search interstitial");
              onLoginsFinished();
              setLoginsFinished(true);
              clearInterval(interval);
            }
          }, 500);
          return () => {
            clearInterval(interval);
          }
        }
        return () => {};
    }, [loginBoxCenterX, loginBoxY, limitSet, enhancedSupplierConnectionStatuses, currentBuyingPharmacy, quoteRequestState, loginsFinished]);

    useEffect(() => {
        if(currentBuyingPharmacy) {
            const startTime = new Date().getTime();
            const limitedSuppliers = Array.from(new Set(currentBuyingPharmacy.suppliers.map(s => s.name).filter(s => limitSet.has(s))));
            const intervalLength = 12000 / limitedSuppliers.length;
            const interval = setInterval(() => {
                const delta: number = new Date().getTime() - startTime;
                const idx: number = Math.floor((delta / intervalLength)) % limitedSuppliers.length;
                const supplier = currentBuyingPharmacy.suppliers.find(supplier => supplier.name === limitedSuppliers[idx]);
                setCurrentSearchingName(supplier?.displayName);
            }, intervalLength);
            return () => {
                clearInterval(interval);
            }
        } else {
          return () => {}
        }
    }, [currentBuyingPharmacy, limitSet]);

    useEffect(() => {
        if(enhancementBeginTime < Number.MAX_VALUE) {
            console.log("Setting transition timeout for enhancements");
            const t = setTimeout(() => {
                const currentTime = new Date().getTime();
                if (currentTime >= enhancementBeginTime + (enhancementTimeout * 0.5)) {
                    console.log("Executing transition timeout for enhancements; forcing transition", currentTime, enhancementBeginTime, currentTime - enhancementBeginTime);
                    onTimeout();
                }
            }, enhancementTimeout);
            return () => {
                console.log("Clearing transition timeout for enhancements");
                clearTimeout(t);
            }
        } else {
            return () => {};
        }
    }, [enhancementBeginTime, enhancementTimeout]);

    useEffect(() => {
      const handleWindowMessage = (event: MessageEvent) => {
        if (event.source !== window) {
          return;
        }
        if (event.data.channel === "daylightrxenhanced") {
          if (event.data.msgType === 'cancelSupplierLogin') {
            const supplier = event.data.payload.supplier;
            onCancelSupplierLogin(supplier);
          }
        }
      };
      window.addEventListener("message", handleWindowMessage);
      return () => {
        window.removeEventListener("message", handleWindowMessage);
      }
    }, []);

    useEffect(() => {
        return () => {
            console.log("EnhancedSearchInterstitial:unmount - showMain");
            showMain();
        }
    }, []);

    useEffect(() => {
      if (expectedMaxEnhancementTimeSeconds && !currentLogin) {
        console.log(`Creating ${expectedMaxEnhancementTimeSeconds} second timeout for search enhancements, searchId: ${searchId} currentLogin: ${currentLogin}`);
        const t = setTimeout(() => {
          console.log(`Search enhancements have taken > ${expectedMaxEnhancementTimeSeconds} seconds; logging Sentry error, searchId: ${searchId} currentLogin: ${currentLogin}`);
          Sentry.captureMessage(`Search enhancements have taken > ${expectedMaxEnhancementTimeSeconds} seconds; logging Sentry error, searchId: ${searchId}`, "error");
        }, expectedMaxEnhancementTimeSeconds * 1000);
        return () => {
          console.log("Clearing search enhancements timeout, searchId:", searchId, "currentLogin:", currentLogin);
          clearTimeout(t);
        }
      } else {
        return () => {};
      }
    }, [searchId, currentLogin, expectedMaxEnhancementTimeSeconds]);

    return (
        <div tw="flex w-full px-48 items-center text-center">
            <div tw="w-full bg-white flex flex-col min-h-[400px] px-4">
                <div tw="text-xl text-center">
                    {currentLogin && `Sign in to ${currentLogin}:`}
                    {!currentLogin && !disableSearchingDisplay && currentSearchingName && `Searching ${currentSearchingName}...`}
                    &nbsp;
                </div>
                <div tw="mx-auto" id="loginBoxRef" ref={loginBoxRefCallback}>
                    {currentLogin ? <>&nbsp;</> : <img src='/assets/search01.gif' />}
                </div>
            </div>
        </div>
    )
}
