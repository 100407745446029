import { useCallback } from "react";
import { AxiosResponse } from "axios";
import { authService } from "../../../libs/Authentication";
import { BackendResponse } from "../../../services/legacy/config/backend-api";
import {
  requestClient,
  RequestClient,
  RequestClientConfig,
} from "../../../services/request/requestClient";
import { OnSubmit } from "./useHandleSubmit";

export function useCreateFormLegacy<
  I extends Record<string, any>,
  O extends Record<string, any>
>({
  redirectTo,
  create,
}: {
  redirectTo: string;
  create: (token: string, data: I) => Promise<BackendResponse<O>>;
}) {
  const onSubmit: OnSubmit<I> = useCallback(
    async (values) => {
      const token = await authService.getAccessTokenSilently();
      const response = await create(token, values);
      return { response, redirectTo };
    },
    [create]
  );

  return onSubmit;
}

export function useCreateForm<
  I extends Record<string, any>,
  O extends Record<string, any>
>({
  redirectTo,
  create,
}: {
  redirectTo: string;
  create: (
    client: RequestClient,
    data: RequestClientConfig<I>
  ) => Promise<void | AxiosResponse<{ data: O }>>;
}) {
  const onSubmit: OnSubmit<I> = useCallback(
    async (values) => {
      let formattedResponse: BackendResponse<O> = { error: true };

      try {
        const response = await create(requestClient, values);
        const data = response?.data?.data;
        if (data) formattedResponse = { data };
      } catch (error: any) {
       const errorResponse = error?.response?.data;
        if (formattedResponse) formattedResponse = errorResponse;
      }

      return { response: formattedResponse, redirectTo };
    },
    [create]
  );

  return onSubmit;
}
