import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from "react";
import * as Sentry from "@sentry/react";
import keyBy from "lodash/keyBy";
import isEqual from "lodash/isEqual";
import type { OptimizedCartResponse_All } from "../../../services/utils";
import type {
  PushBlobOp,
  ShoppingCartMutation,
  ShoppingCartMutationBatch,
} from "./ShoppingCartServerContext.types";
import {
  ItemInCart,
  ServerState,
  ItemPurchaseDetails,
  PurchaseQuantityMethodEnum,
  PrescriptionGroupAdjustment,
} from "../../../utilities/types";
import { ensureArray } from "../../../utilities/arrays/ensureArray";
import { getPrescriptionId } from "../../../utilities/prescriptions/getPrescriptionId";
import { findAndRemoveFromArray } from "../../../utilities/arrays/findAndRemoveFromArray";
import {
  formatItemInCart,
  formatItemPurchaseDetails,
} from "../../../utilities/prescriptions/formatItem";
import { insertArray } from "../../../utilities/arrays/insertArray";
import { cleanServerState } from "../../../utilities/prescriptions/cleanServerState";
import {
  getJSONBlob,
  pushJSONBlob,
  getBlobVersionId,
} from "../../../services/prescriptions";
import { updateOptimizedCartSelections } from "../../../services/legacy/optimizations";
import { useServerUpdateNotifications } from "../ServerUpdateNotificationsContext";
import { useBuyingPharmacy } from "../../BuyingPharmacyContext";
import { authService } from "../../../libs/Authentication";

/**
 * Return the original array if the filtered array is the same length as the original array.
 * It is assumed that the filtered array is a subset of the original array.
 * Its purpose is to prevent unnecessary changes in the state and therefore unnecessary re-renders.
 */
function reduceFilterStateChanges<A>(original: A[], filtered: A[]): A[] {
  return filtered.length === original.length ? original : filtered;
}

const ShoppingCartServerStateContext = createContext<
  | ReturnType<typeof useShoppingCartServerContextProvider>["serverState"]
  | undefined
>(undefined);

const ShoppingCartServerUpdaterContext = createContext<
  | ReturnType<typeof useShoppingCartServerContextProvider>["serverUpdater"]
  | undefined
>(undefined);

function useShoppingCartServerContextProvider() {
  const { currentBuyingPharmacyId: pharmacyId } = useBuyingPharmacy();
  const { activeCartUpdatedEvent } = useServerUpdateNotifications();
  const [isCartStateLoading, setIsCartStateLoading] = useState(false);
  const [cartUpdateExists, setCartUpdateExists] = useState(false);
  const [serverState, setServerState] = useState(cleanServerState);
  const [initialShoppingPath, setInitialShoppingPath] =
    useState("/shoppingList");

  const [shoppingCartMutationSubmissions, setShoppingCartMutationSubmissions] =
    useState<ShoppingCartMutation[]>([]);
  const [mutationBatchId, setMutationBatchId] = useState(0);
  const [shoppingCartMutations, setShoppingCartMutations] = useState<
    ShoppingCartMutationBatch[]
  >([]);
  const [shoppingCartMutationUndos, setShoppingCartMutationUndos] = useState<
    ShoppingCartMutation[]
  >([]);
  const [useBlob, setUseBlob] = useState(false);
  const [pushBlobOp, setPushBlobOp] = useState<PushBlobOp | null>(null);
  const [serverStateConflict, setServerStateConflict] = useState<
    ServerState & { op: PushBlobOp }
  >();
  const [optimizationUpdates, setOptimizationUpdates] = useState<
    { optimization: OptimizedCartResponse_All; editVersion: number }[]
  >([]);

  const createdShoppingCartMutations = useMemo(() => {
    return shoppingCartMutations.filter((m) => m.status === "created");
  }, [shoppingCartMutations]);

  const pendingShoppingCartMutations = useMemo(() => {
    return shoppingCartMutations.filter((m) => m.status === "pending");
  }, [shoppingCartMutations]);

  const submittingShoppingCartMutations = useMemo(() => {
    return shoppingCartMutations.filter((m) => m.status === "submitting");
  }, [shoppingCartMutations]);

  const loadCartState = useCallback(async () => {
    if (!pharmacyId) return;

    setIsCartStateLoading(true);
    const token = await authService.getAccessTokenSilently();
    const response = await getJSONBlob(pharmacyId, token);
    const newServerState = cleanServerState(response?.data);
    setServerState((prevState) => {
      if (isEqual(prevState, newServerState)) return prevState;
      return newServerState;
    });
    setIsCartStateLoading(false);
  }, [pharmacyId]);

  const addItemPurchaseDetailsList = useCallback((items: ItemPurchaseDetails[]) => {
    const itemsToAdd = items.map((item) => {
      const newItem = formatItemPurchaseDetails({
        ...item,
        status: "list",
        addedAt: undefined,
      });
      return newItem;
    });

    setServerState((prevState): ServerState => {
      const { cart } = prevState;
      const itemsById = keyBy(cart, "id");
      itemsToAdd.forEach((item) => (itemsById[item.id] = item));
      const newCart = Object.values(itemsById);
      return { ...prevState, cart: newCart };
    });
  }, []);

  const removeFromCart = useCallback((value: string | string[]) => {
    const ids = ensureArray(value);
    if (ids.length === 0) return;

    const idsSet = new Set(ids);
    setServerState((prevState): ServerState => {
      const { cart, inventory } = prevState;
      const filteredCart = cart.filter((item) => !idsSet.has(item.id));
      const filteredInventory = inventory.filter((item) => {
        return !idsSet.has(getPrescriptionId(item));
      });
      const newCart = reduceFilterStateChanges(cart, filteredCart);
      const newInventory = reduceFilterStateChanges(
        inventory,
        filteredInventory
      );
      return { ...prevState, cart: newCart, inventory: newInventory };
    });
  }, []);

  const addOrUpdateItemInPurchaseDetail = useCallback((item: ItemInCart) => {
    const newItem = formatItemPurchaseDetails(item);
    setServerState((prevState): ServerState => {
      const newCart = prevState.cart.filter((i) => i.id !== newItem.id);
      newCart.push(newItem);
      return { ...prevState, cart: newCart };
    });
  }, []);

  const updateItemPurchaseDetailValue = useCallback(
    <K extends keyof ItemPurchaseDetails>(
      id: string,
      key: K,
      value: ItemPurchaseDetails[K]
    ) => {
      setServerState((prevState): ServerState => {
        const [newCart, item] = findAndRemoveFromArray(prevState.cart, (i) => {
          return i.id === id;
        });
        if (!item) return prevState;

        const newItem = formatItemPurchaseDetails({ ...item, [key]: value });
        newCart.push(newItem);
        return { ...prevState, cart: newCart };
      });
    },
    []
  );

  const addInventoryItem = useCallback((item: ItemInCart) => {
    const newItem = formatItemInCart(item);
    setServerState((prevState): ServerState => {
      const newInventory = [...prevState.inventory, newItem];
      return { ...prevState, inventory: newInventory };
    });
  }, []);

  const updateInventoryItem = useCallback(
    <K extends keyof ItemInCart>(
      key: string,
      field: K,
      value: ItemInCart[K]
    ) => {
      setServerState((prevState): ServerState => {
        const [newInventory, item] = findAndRemoveFromArray(
          prevState.inventory,
          (i) => i.key === key
        );
        if (!item) return prevState;

        const newItem = formatItemInCart({ ...item, [field]: value });
        newInventory.push(newItem);
        return { ...prevState, inventory: newInventory };
      });
    },
    []
  );

  const updatePrescriptionGroupPurchaseQuantity = useCallback(
    (
      rxNumbers: string[],
      purchaseQuantityMethod: PurchaseQuantityMethodEnum,
      num?: string
    ) => {
      setServerState((prevState): ServerState => {
        const [newAdjustments, adjustment] = findAndRemoveFromArray(
          prevState.prescriptionGroupAdjustments,
          (item) => isEqual(item.rxNumbers, rxNumbers)
        );

        const useQuantityInput =
          purchaseQuantityMethod === PurchaseQuantityMethodEnum.Manual;
        const newAdjustment = { ...adjustment, rxNumbers, useQuantityInput };
        if (num !== undefined) {
          if (useQuantityInput) {
            newAdjustment.quantityToBuy = parseInt(num, 10);
          } else {
            newAdjustment.numPackages = parseInt(num, 10);
          }
        }
        newAdjustments.push(newAdjustment);

        return { ...prevState, prescriptionGroupAdjustments: newAdjustments };
      });
    },
    []
  );

  const updatePrescriptionGroupChosenSubstitution = useCallback(
    (
      rxNumbers: string[],
      allowPackSizeSubstitution: boolean,
      allowManufacturerSubstitution: boolean
    ) => {
      setServerState((prevState): ServerState => {
        const [adjustments, adjustment, adjustmentIndex] =
          findAndRemoveFromArray(
            prevState.prescriptionGroupAdjustments,
            (item) => isEqual(item.rxNumbers, rxNumbers)
          );
        const newAdjustment = {
          ...(adjustment ? adjustment : { rxNumbers }),
          allowManufacturerSubstitution,
          allowPackSizeSubstitution,
        };
        const newAdjustments = insertArray(
          adjustments,
          newAdjustment,
          adjustmentIndex
        );
        return { ...prevState, prescriptionGroupAdjustments: newAdjustments };
      });
    },
    []
  );

  const deletePrescriptionGroupAdjustments = useCallback(
    (adjustmentsToDelete: PrescriptionGroupAdjustment[]) => {
      setServerState((prevState): ServerState => {
        let newAdjustments = prevState.prescriptionGroupAdjustments;
        adjustmentsToDelete.forEach((adjustmentToDelete) => {
          newAdjustments = newAdjustments.filter(
            (adjustment) =>
              adjustment.rxNumbers.join(",") !==
              adjustmentToDelete.rxNumbers.join(",")
          );
        });
        return { ...prevState, prescriptionGroupAdjustments: newAdjustments };
      });
    },
    []
  );

  const addPrescriptionGroupAdjustments = useCallback(
    (adjustmentsToAdd: PrescriptionGroupAdjustment[]) => {
      setServerState((prevState): ServerState => {
        const newAdjustments = [...prevState.prescriptionGroupAdjustments];
        adjustmentsToAdd.forEach((adjustmentToAdd) => {
          newAdjustments.push(adjustmentToAdd);
        });
        return { ...prevState, prescriptionGroupAdjustments: newAdjustments };
      });
    },
    []
  );

  const applyMutation = useCallback(
    (mutation: ShoppingCartMutation) => {
      if (mutation.name === "removeFromCart") {
        removeFromCart(mutation.params.ids);
      } else if (mutation.name === "addItemPurchaseDetailsList") {
        addItemPurchaseDetailsList(mutation.params.items);
      } else if (mutation.name === "updateItemPurchaseDetailValue") {
        updateItemPurchaseDetailValue(
          mutation.params.id,
          mutation.params.key,
          mutation.params.value
        );
      } else if (mutation.name === "addInventoryItem") {
        addInventoryItem(mutation.params.item);
      } else if (mutation.name === "addOrUpdateItemInPurchaseDetail") {
        addOrUpdateItemInPurchaseDetail(mutation.params.item);
      } else if (mutation.name === "updateInventoryItem") {
        updateInventoryItem(
          mutation.params.key,
          mutation.params.field,
          mutation.params.value
        );
      } else if (mutation.name === "updatePrescriptionGroupPurchaseQuantity") {
        updatePrescriptionGroupPurchaseQuantity(
          mutation.params.rxNumbers,
          mutation.params.purchaseQuantityMethod,
          mutation.params.num
        );
      } else if (
        mutation.name === "updatePrescriptionGroupChosenSubstitution"
      ) {
        updatePrescriptionGroupChosenSubstitution(
          mutation.params.rxNumbers,
          mutation.params.allowPackSizeSubstitution,
          mutation.params.allowManufacturerSubstitution
        );
      } else if (mutation.name === "deletePrescriptionGroupAdjustments") {
        deletePrescriptionGroupAdjustments(mutation.params.adjustments);
      } else if (mutation.name === "addPrescriptionGroupAdjustments") {
        addPrescriptionGroupAdjustments(mutation.params.adjustments);
      }
    },
    [
      removeFromCart,
      addInventoryItem,
      updateInventoryItem,
      addItemPurchaseDetailsList,
      updateItemPurchaseDetailValue,
      addOrUpdateItemInPurchaseDetail,
      addPrescriptionGroupAdjustments,
      deletePrescriptionGroupAdjustments,
      updatePrescriptionGroupPurchaseQuantity,
      updatePrescriptionGroupChosenSubstitution,
    ]
  );

  const submitShoppingCartMutation = useCallback(
    (mutation: ShoppingCartMutation) => {
      console.log("submitShoppingCartMutation", mutation);
      setShoppingCartMutationSubmissions((prev) => {
        return [...prev, mutation];
      });
    },
    []
  );

  const markMutationBatchStatus = useCallback(
    (
      status: ShoppingCartMutationBatch["status"],
      mutationBatches: ShoppingCartMutationBatch[]
    ) => {
      setShoppingCartMutations((prev) => {
        return prev.map((mutationBatch) => {
          if (
            mutationBatches.find(
              (mutationBatchToMark) =>
                mutationBatchToMark.id === mutationBatch.id
            )
          ) {
            return {
              ...mutationBatch,
              status: status,
            };
          } else {
            return mutationBatch;
          }
        });
      });
    },
    []
  );

  const reverseUndoableMutations = useCallback(() => {
    const stack = [...shoppingCartMutationUndos];
    stack.reverse();
    stack.forEach((mutation) => {
      if (
        mutation.name === "updateItemPurchaseDetailValue" &&
        mutation.options?.undo
      ) {
        submitShoppingCartMutation({
          name: "updateItemPurchaseDetailValue",
          params: {
            id: mutation.options.undo.id,
            key: mutation.options.undo.key,
            value: mutation.options.undo.value,
          },
        });
      } else if (
        mutation.name === "updateInventoryItem" &&
        mutation.options?.undo
      ) {
        submitShoppingCartMutation({
          name: "updateInventoryItem",
          params: {
            key: mutation.options.undo.key,
            field: mutation.options.undo.field,
            value: mutation.options.undo.value,
          },
        });
      } else if (
        mutation.name === "deletePrescriptionGroupAdjustments" &&
        mutation.options?.undo
      ) {
        submitShoppingCartMutation({
          name: "addPrescriptionGroupAdjustments",
          params: {
            adjustments: mutation.options.undo.adjustments,
          },
        });
      }
    });
    setShoppingCartMutationUndos((prev) => {
      return prev.filter((mutation) => !stack.includes(mutation));
    });
  }, [
    shoppingCartMutationUndos,
    submitShoppingCartMutation,
    setShoppingCartMutationUndos,
  ]);

  const clearMutationUndoStack = useCallback(() => {
    setShoppingCartMutationUndos([]);
  }, []);

  const pushBlob = useCallback(
    (
      config:
        | { second: boolean; force: false }
        | { force: true; second?: boolean; cb?: () => void }
    ) => {
      setPushBlobOp(config);
    },
    [setPushBlobOp]
  );

  const submitOptimizationUpdate = useCallback(
    async (optimization: OptimizedCartResponse_All) => {
      if (optimization.data) {
        const editVersion = optimization.data.editVersion;
        setOptimizationUpdates((prev) => [
          ...prev,
          {
            optimization: optimization,
            editVersion: editVersion,
            executed: false,
          },
        ]);
      }
    },
    [setOptimizationUpdates]
  );

  useEffect(() => {
    if (activeCartUpdatedEvent > 0) {
      setCartUpdateExists(true);
    }
  }, [activeCartUpdatedEvent, setCartUpdateExists]);

  useEffect(() => {
    const newServerState = cleanServerState();
    setServerState(newServerState);
  }, [pharmacyId]);

  useEffect(() => {
    if (!pharmacyId || !shoppingCartMutationSubmissions.length) return;

    const newShoppingCartMutations = [...shoppingCartMutations];
    const mutationBatch = newShoppingCartMutations.find(
      (mutationBatch) => mutationBatch.status === "created"
    );
    if (mutationBatch) {
      mutationBatch.mutations.push(...shoppingCartMutationSubmissions);
    } else {
      newShoppingCartMutations.push({
        id: mutationBatchId,
        mutations: [...shoppingCartMutationSubmissions],
        status: "created",
        submitTime: Date.now(),
        clientBlobVersion: getBlobVersionId(pharmacyId),
      });
      setMutationBatchId(mutationBatchId + 1);
    }
    console.log(
      "submitShoppingCartMutation mutations now",
      newShoppingCartMutations
    );
    setShoppingCartMutations(newShoppingCartMutations);
    setShoppingCartMutationSubmissions((prev) =>
      prev.filter((x) => !shoppingCartMutationSubmissions.includes(x))
    );
  }, [
    pharmacyId,
    shoppingCartMutations,
    shoppingCartMutationSubmissions,
    mutationBatchId,
    setShoppingCartMutations,
    setShoppingCartMutationSubmissions,
    setMutationBatchId,
  ]);

  useEffect(() => {
    if (createdShoppingCartMutations.length > 0) {
      createdShoppingCartMutations.forEach((batch) => {
        const mutations = batch.mutations;
        mutations.forEach((mutation) => {
          console.log("applying mutation", mutation);
          applyMutation(mutation);
          if (
            (mutation.name === "updateItemPurchaseDetailValue" ||
              mutation.name === "deletePrescriptionGroupAdjustments" ||
              mutation.name === "updateInventoryItem") &&
            mutation.options?.undo
          ) {
            setShoppingCartMutationUndos((prev) => [...prev, mutation]);
          }
        });
      });
      markMutationBatchStatus("pending", createdShoppingCartMutations);
    }
  }, [
    createdShoppingCartMutations,
    applyMutation,
    markMutationBatchStatus,
    setShoppingCartMutationUndos,
  ]);

  useEffect(() => {
    (async () => {
      if (optimizationUpdates.length > 0) {
        const update = optimizationUpdates.reduce((acc, cur) => {
          if (cur.editVersion > acc.editVersion) {
            return cur;
          } else {
            return acc;
          }
        }, optimizationUpdates[0]);
        const reviewedOptimizationUpdates = [...optimizationUpdates];
        const optimization = update.optimization;
        if (optimization.data) {
          const accessToken = await authService.getAccessTokenSilently();
          updateOptimizedCartSelections(
            optimization.data.id,
            accessToken,
            optimization.data.selections
          );
        }
        setOptimizationUpdates((prev) => {
          return prev.filter(
            (update) =>
              !reviewedOptimizationUpdates
                .map((u) => u.editVersion)
                .includes(update.editVersion)
          );
        });
      }
    })();
  }, [optimizationUpdates, setOptimizationUpdates]);

  useEffect(() => {
    if (
      shoppingCartMutations.find(
        (mutationBatch) => mutationBatch.status === "submitted"
      )
    ) {
      setShoppingCartMutations((prev) => {
        return prev.filter(
          (mutationBatch) => mutationBatch.status !== "submitted"
        );
      });
    }
  }, [shoppingCartMutations]);

  useEffect(() => {
    (async () => {
      if (
        !pushBlobOp ||
        !pharmacyId ||
        serverStateConflict ||
        shoppingCartMutationSubmissions.length > 0 ||
        createdShoppingCartMutations.length > 0
      ) {
        return;
      }

      setPushBlobOp(null);
      setCartUpdateExists(false);
      if (!(useBlob || pushBlobOp.force)) return;

      let conflict = false;
      if (pendingShoppingCartMutations.length > 0) {
        const jsonBlob = {
          ...cleanServerState(serverState, { cleanForPush: true }),
          second: pushBlobOp.second,
        };

        console.log(
          `----- Pushed Change at ${new Date()} for - ${pharmacyId}, changes:`,
          pendingShoppingCartMutations,
          jsonBlob
        );
        // Push Cart to Server
        markMutationBatchStatus("submitting", pendingShoppingCartMutations);
        const accessToken = await authService.getAccessTokenSilently();
        const pushResponse = await pushJSONBlob(
          accessToken,
          pharmacyId,
          jsonBlob
        );
        console.log(pushResponse);
        if (pushResponse.status === 200) {
          markMutationBatchStatus("submitted", pendingShoppingCartMutations);
        } else {
          if (pushResponse.data) {
            console.log("Conflict detected; marking for resolution");
            const newServerConflictCart = {
              ...cleanServerState(pushResponse.data.data),
              op: pushBlobOp,
            };
            setServerStateConflict(newServerConflictCart);
            conflict = true;
          }
        }
      } else if (
        cartUpdateExists &&
        createdShoppingCartMutations.length === 0
      ) {
        const token = await authService.getAccessTokenSilently();
        const oldVersionId = getBlobVersionId(pharmacyId);
        const newBlobResponse = await getJSONBlob(pharmacyId, token);
        if (
          newBlobResponse?.data &&
          newBlobResponse.versionId !== oldVersionId
        ) {
          const newServerConflictCart = {
            ...cleanServerState(newBlobResponse.data),
            op: pushBlobOp,
          };
          setServerStateConflict(newServerConflictCart);
          conflict = true;
        }
      }

      if (!conflict && pushBlobOp.force && pushBlobOp.cb) {
        pushBlobOp.cb();
      }
    })();
  }, [
    useBlob,
    pushBlobOp,
    pharmacyId,
    serverState,
    cartUpdateExists,
    serverStateConflict,
    createdShoppingCartMutations,
    pendingShoppingCartMutations,
    shoppingCartMutationSubmissions,
    setCartUpdateExists,
    setServerStateConflict,
    markMutationBatchStatus,
  ]);

  useEffect(() => {
    if (!serverStateConflict) return;

    console.log("Resolving server conflict");
    const newServerState = cleanServerState(serverStateConflict);
    setServerState(newServerState);
    submittingShoppingCartMutations.forEach((mutationBatch) => {
      mutationBatch.mutations.forEach((mutation) => {
        console.log("Applying post-conflict mutation", mutation);
        applyMutation(mutation);
      });
    });
    markMutationBatchStatus("pending", submittingShoppingCartMutations);
    setServerState((prev) => {
      console.log("Post conflict server state", prev);
      return prev;
    });
    setServerStateConflict(undefined);
    setPushBlobOp(serverStateConflict.op);
  }, [
    serverStateConflict,
    submittingShoppingCartMutations,
    applyMutation,
    setServerState,
    markMutationBatchStatus,
  ]);

  useEffect(() => {
    const cart = serverState.cart;
    if (!cart.length) return;
    const hasItemsWithoutItemTypes = cart.some((item) => {
      return !item.itemType;
    });
    if (!hasItemsWithoutItemTypes) return;
    Sentry.captureMessage("Item in cart without itemType", {
      level: "error",
      extra: { cart },
    });
  }, [serverState.cart]);

  return {
    serverState: { ...serverState, isCartStateLoading, initialShoppingPath },
    serverUpdater: {
      pushBlob,
      setUseBlob,
      loadCartState,
      setInitialShoppingPath,
      clearMutationUndoStack,
      reverseUndoableMutations,
      submitOptimizationUpdate,
      submitShoppingCartMutation,
    },
  };
}

export function ShoppingCartServerContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { serverState, serverUpdater } = useShoppingCartServerContextProvider();
  return (
    <ShoppingCartServerStateContext.Provider value={serverState}>
      <ShoppingCartServerUpdaterContext.Provider value={serverUpdater}>
        {children}
      </ShoppingCartServerUpdaterContext.Provider>
    </ShoppingCartServerStateContext.Provider>
  );
}

export function useShoppingCartServerState() {
  const context = useContext(ShoppingCartServerStateContext);
  if (context === undefined) {
    throw new Error(
      "useShoppingCartServerState must be used within a ShoppingCartServerContextProvider"
    );
  }
  return context;
}

export function useShoppingCartServerUpdater() {
  const context = useContext(ShoppingCartServerUpdaterContext);
  if (context === undefined) {
    throw new Error(
      "useShoppingCartServerUpdater must be used within a ShoppingCartServerContextProvider"
    );
  }
  return context;
}
